import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import SideBarBg from '@/assets/images/login-sidebar-bg.png';
import bg from '@/assets/images/bg.png';

const SidebarWidth = {
  xl: 90,
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      overflowX: 'hidden',
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      height: 'auto',
      padding: 0,
      alignItems: 'stretch',
      backgroundColor: `${theme.palette.background.paper}`,
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    content: {
      display: 'flex',
      width: `calc(100% - ${SidebarWidth.xl}px)`,
      alignSelf: 'stretch',
      flex: 1,
      flexDirection: 'column',
      height: 'auto',
      minHeight: '100vh',
      position: 'absolute',
      alignItems: 'flex-start',
      top: 0,
      right: 0,
      left: SidebarWidth.xl,
      backgroundColor: `${theme.palette.background.paper}`,
      [theme.breakpoints.down('sm')]: {
        position: 'initial',
        width: '100%',
        minWidth: '100%',
        minHeight: 'auto',
        height: 'auto',
        top: 'auto',
        right: 'auto',
        bottom: 'auto',
        left: 0,
      },
    },
    sidebar: {
      width: SidebarWidth.xl,
      minWidth: SidebarWidth.xl,
      height: '100%',
      minHeight: '100vh',
      position: 'fixed',
      background: `${theme.andorTheme.pearl} url(${bg}) no-repeat !important`,
      backgroundPosition: 'center center !important',
      backgroundSize: 'cover !important',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        position: 'initial',
        width: '100%',
        minWidth: '100%',
        minHeight: 0,
        height: 'auto',
        zIndex: 100,
      },
    },
    sidebarBg: {
      width: SidebarWidth.xl,
      minWidth: SidebarWidth.xl,
      height: '100%',
      minHeight: '100vh',
      position: 'fixed',
      background: `${theme.andorTheme.pearl} url(${SideBarBg}) no-repeat !important`,
      backgroundPosition: 'center center !important',
      backgroundSize: 'cover !important',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        position: 'initial',
        width: '100%',
        minWidth: '100%',
        minHeight: 0,
        height: 'auto',
        zIndex: 100,
      },
    },
    sidebarHeader: {
      display: 'flex',
      flexDirection: 'row',
      width: '100%',
      padding: theme.spacing(3),
      alignItems: 'center',
      marginBottom: theme.spacing(4),
    },
    headerLogo: {
      width: 50,
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
      },
    },
    headerLogoContainer: {
      width: '100%',
      textAlign: 'center',
      [theme.breakpoints.down('sm')]: {
        flexGrow: 1,
      },
    },
    leftHeaderComp: {
      width: 'auto',
      height: 'auto',
      margin: 0,
      padding: 0,
    },
    rightHeaderComp: {
      width: 'auto',
      height: 'auto',
      margin: 0,
      padding: 0,
      marginLeft: 'auto',
    },
    sidebarContent: {
      display: 'flex',
      flexGrow: 1,
      width: '100%',
      flexDirection: 'column',
    },
    footer: { width: '100%' },
  }),
);

export default useStyles;
