import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    sidebarFooter: {
      display: 'flex',
      alignItems: 'flex-start',
      justifyContent: 'center',
      flexDirection: 'row',
      width: '100%',
      paddingBottom: 20,
      paddingTop: 10,
      '& > img': {
        width: 36,
        height: 36,
        borderRadius: '100%',
      },
      '& section': {
        flex: 1,
      },
    },
    profilePic: {
      fontSize: 30,
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(2),
    },
    logout: {
      color: theme.andorTheme.shark,
      fontWeight: 600,
      fontSize: '0.88em',
      lineHeight: '1.5em',
      padding: '0 0.5em',
      display: 'flex',
      alignItems: 'center',
      textAlign: 'center',
    },
    logoutIcon: {
      fontSize: 25,
    },
    profileBtn: {
      width: '100%',
      '& .MuiButton-label': {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    profileDisabledBtn: {
      width: '100%',
      justifyContent: 'flex-start',
      '& .MuiButton-label': {
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'flex-start',
      },
    },
    accountType: {
      color: theme.andorTheme.charcoal,
      textAlign: 'left',
    },
    fullName: {
      color: theme.andorTheme.charcoal,
      fontWeight: 600,
    },
    profileIcon: {
      fontSize: 14,
      marginLeft: 'auto',
    },
  }),
);

export default useStyles;
