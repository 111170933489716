import { Instance, types } from 'mobx-state-tree';

const ChartPieData = types.model({
  id: types.number,
  value: types.number,
  label: types.string,
});

const ByUserBarData = types.model({
  assignedUser: types.string,
  open: types.number,
  closed: types.number,
  noProceed: types.number,
  total: types.number,
});

const AccidentsKpis = types.model({
  byInsurers: types.array(ChartPieData),
  byStatus: types.array(ChartPieData),
  byAccidentTypes: types.array(ChartPieData),
  byAssignedUser: types.array(ByUserBarData),
});

export interface IAccidentsKpis extends Instance<typeof AccidentsKpis> {}
export default AccidentsKpis;
