import { FC } from 'react';
import Button from '@material-ui/core/Button';

import { IoLogOut } from 'react-icons/io5';
import useStyles from './styles';

export interface ISidebarUserFooter {
  logout?: () => void;
  goToProfile?: () => void;
  logoutStr: string;
  fullName?: string | undefined | null;
  profilePicUrl?: string;
  accountType: string;
  buttonLogoutId?: string;
  buttonAccountId?: string;
  disableGoToProfile?: boolean;
}

const SidebarUserFooter: FC<ISidebarUserFooter> = ({
  logout,
  logoutStr,
  buttonLogoutId = 'btn_logout',
}) => {
  const css = useStyles();

  return (
    <div className={css.sidebarFooter} data-testid="sidebarFooter">
      <Button
        className={css.logout}
        onClick={logout}
        id={buttonLogoutId}
        data-testid="sidebarFooterLogout"
      >
        {logoutStr}
        <IoLogOut className={css.logoutIcon} />
      </Button>
    </div>
  );
};

export default SidebarUserFooter;
