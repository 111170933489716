import { ReactElement, FC, ReactNode } from 'react';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

import { Theme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import logo from '@/assets/images/logo.png';
import useStyles from './styles';

export interface IWebDashboardLayout {
  children: ReactNode;
  contentClassName?: string;
  sidebarClassName?: string;
  rightHeaderComp?: ReactElement;
  leftHeaderComp?: ReactElement;
  sidebarComp?: ReactElement;
  sidebarMenu?: ReactElement;
  footerComp?: ReactElement;
  showBgImg?: boolean;
}

const WebDashboardLayout: FC<IWebDashboardLayout> = ({
  children,
  sidebarClassName,
  contentClassName,
  rightHeaderComp,
  leftHeaderComp,
  sidebarComp,
  sidebarMenu,
  footerComp,
  showBgImg = false,
}) => {
  const css = useStyles();
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));

  const sidebarCss = clsx(showBgImg ? css.sidebarBg : css.sidebar, sidebarClassName);
  const contentCss = clsx(css.content, contentClassName);

  return (
    <div className={css.root}>
      <div className={sidebarCss}>
        <header className={css.sidebarHeader}>
          {isSmallScreen && leftHeaderComp && (
            <div className={css.leftHeaderComp}>{leftHeaderComp}</div>
          )}
          <div className={css.headerLogoContainer}>
            <img alt="logo" src={logo} className={css.headerLogo} />
          </div>
          {rightHeaderComp && <div className={css.rightHeaderComp}>{rightHeaderComp}</div>}
        </header>
        <section className={css.sidebarContent}>
          {sidebarComp}
          {sidebarMenu}
        </section>
        {!isSmallScreen && footerComp && (
          <footer className={css.footer} data-testid="sidebarFooter">
            {footerComp}
          </footer>
        )}
      </div>
      <div className={contentCss}>{children}</div>
    </div>
  );
};

export default observer(WebDashboardLayout);
