import { types, applySnapshot, getSnapshot, Instance, flow, cast, getRoot } from 'mobx-state-tree';
import axios from 'axios';
import debug from 'debug';

import { IGlobalStore } from '@/store';
import endpoints from '@/config/endpoints';
import Flags from './models/Flags';
import AccidentTypes from './models/AccidentTypes';
import Accident from './models/Accident';
import AccidentLoss, { IAccidentLoss } from './models/AccidentLoss';
import AccidentLossSummary from './models/AccidentLossSummary';
import AccidentAdjustLoss, { IAccidentAdjustLoss } from './models/AccidentAdjustLoss';
import AccidentEvidenceImage from './models/AccidentEvidenceImage';
import AccidentsKpis from './models/AccidentsKpis';

const log = debug('store:accidents:log');
const errorLog = debug('store:accidents:error');

const AccidentsStore = types
  .model({
    flags: types.optional(Flags, {}),
    accidentsList: types.optional(types.array(Accident), []),
    accidentTypesList: types.optional(types.array(AccidentTypes), []),
    accident: types.optional(Accident, {}),
    accidentLoss: types.optional(AccidentLoss, {}),
    accidentLossList: types.optional(types.array(AccidentLoss), []),
    accidentLossSummary: types.optional(AccidentLossSummary, {}),
    accidentAdjustLossList: types.optional(types.array(AccidentAdjustLoss), []),
    accidentAdjustLoss: types.optional(AccidentAdjustLoss, {}),
    accidentEvidencesImages: types.optional(types.array(AccidentEvidenceImage), []),
    executiveSummary: types.optional(types.string, ''),
    accidentPreliminarReportCreated: types.optional(types.boolean, false),
    accidentFinalReportUrl: types.optional(types.string, ''),
    sectionsReport: types.optional(
      types.array(
        types.model({
          title: types.optional(types.string, ''),
          content: types.optional(types.string, ''),
        }),
      ),
      [],
    ),
    accidentsKpis: types.optional(AccidentsKpis, {}),
  })
  .views((self) => ({
    get root(): IGlobalStore {
      return getRoot(self);
    },
  }))
  .actions((self) => ({
    updateField: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }))
  .actions((self) => {
    let initialState = {};
    return {
      afterCreate: () => {
        initialState = getSnapshot(self);
      },
      resetStore: () => {
        applySnapshot(self, initialState);
      },
      delay: (ms: number) =>
        new Promise((resolve) => {
          setTimeout(resolve, ms);
        }),
    };
  })
  .actions((self) => ({
    getAccidentsList: flow(function* () {
      log('getAccidentsList >>>>');
      try {
        self.flags.isFetchingAccidentListData = true;
        const response = yield axios.get(endpoints.getAccidentList);
        log('getAccidentsListResponse >>>>', response);
        if (response.data.success) {
          self.accidentsList = cast(response.data.accidentsList);
        }
      } catch (err) {
        errorLog('getAccidentsListError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingAccidentListData = false;
      }
    }),
    getAccident: flow(function* ({ accidentId }) {
      log('getAccident >>>>', accidentId);
      try {
        self.flags.isFetchingAccidentData = true;
        const response = yield axios.get(endpoints.getAccident(accidentId));
        log('getAccidentResponse >>>>', response);
        self.accident = cast(response.data.accident);
        return response.data;
      } catch (err) {
        errorLog('getAccidentError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingAccidentData = false;
      }
    }),
    getAccidentLossList: flow(function* ({ accidentId }) {
      log('getAccidentLossList >>>>', accidentId);
      try {
        self.flags.isFetchingAccidentLossListData = true;
        const response = yield axios.get(endpoints.getAccidentLossList(accidentId));
        log('getAccidentLossListResponse >>>>', response);
        if (response.data.success) {
          self.accidentLossList = cast(response.data.accidentLossList);
        }
      } catch (err) {
        errorLog('getAccidentLossListError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingAccidentLossListData = false;
      }
    }),
    getAccidentLossSummary: flow(function* ({ accidentId }) {
      log('getAccidentLossSummary >>>>', accidentId);
      try {
        self.flags.isFetchingAccidentLossSummaryData = true;
        const response = yield axios.get(endpoints.getAccidentLossSummary(accidentId));
        log('getAccidentLossSummaryResponse >>>>', response);
        if (response.data.success) {
          self.accidentLossSummary = cast(response.data.accidentLossSummary);
          self.accidentAdjustLossList = cast(response.data.accidentAdjustLossList);
        }
      } catch (err) {
        errorLog('getAccidentLossSummaryError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingAccidentLossSummaryData = false;
      }
    }),
    getAccidentEvidencesImages: flow(function* ({ accidentId }) {
      log('getAccidentEvidencesImages >>>>', accidentId);
      try {
        self.flags.isFetchingGetAccidentEvidencesImages = true;
        const response = yield axios.get(endpoints.getAccidentEvidencesImages(accidentId));
        log('getAccidentEvidencesImagesResponse >>>>', response);
        if (response.data.success) {
          self.accidentEvidencesImages = cast(response.data.images);
        }
      } catch (err) {
        errorLog('getAccidentEvidencesImagesError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingGetAccidentEvidencesImages = false;
      }
    }),
    getAccidentReport: flow(function* ({ accidentId }) {
      log('getAccidentReport >>>>', accidentId);
      try {
        self.flags.isFetchingGetAccidentEvidencesImages = true;
        const response = yield axios.get(endpoints.getAccidentReport(accidentId));
        log('getAccidentReportResponse >>>>', response);
        if (response.data.success) {
          self.executiveSummary = cast(response.data?.accidentReport?.executiveSummary || '');
          if (response.data?.accidentReport?.sectionsReport) {
            self.sectionsReport = cast(JSON.parse(response.data?.accidentReport?.sectionsReport));
            self.accidentPreliminarReportCreated = true;
            self.accidentFinalReportUrl = response.data?.accidentReport?.finalReportFileUrl || '';
          } else {
            self.accidentPreliminarReportCreated = false;
          }
          if (Object.keys(response.data?.accidentReport).length === 0) {
            self.sectionsReport = cast([
              {
                title: 'INTRODUCCION',
                content: '',
              },
            ]);
          }
        }
      } catch (err) {
        errorLog('getAccidentReportError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingGetAccidentEvidencesImages = false;
      }
    }),
    getAccidentsKpis: flow(function* ({ year }) {
      log('getAccidentsKpis >>>>', year);
      try {
        self.flags.isFetchingAccidentsKpis = true;
        const response = yield axios.get(endpoints.getAccidentsKpis(year));
        log('getAccidentsKpisResponse >>>>', response);
        if (response.data.success) {
          self.accidentsKpis = cast(response.data.accidentsKpis);
        }
      } catch (err) {
        errorLog('getAccidentsKpisError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingAccidentsKpis = false;
      }
    }),
  }))
  .actions((self) => ({
    getAccidentTypesList: flow(function* () {
      log('getAccidentTypesList >>>>');
      try {
        self.flags.isFetchingAccidentTypesListData = true;
        const response = yield axios.get(endpoints.getAccidentTypesList);
        log('getAccidentTypesListResponse >>>>', response);
        if (response.data.success) {
          self.accidentTypesList = cast(response.data.accidentTypesList);
        }
      } catch (err) {
        errorLog('getAccidentTypesListError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingAccidentTypesListData = false;
      }
    }),
    createAccident: flow(function* (data) {
      log('createAccident >>>>', data);
      try {
        self.flags.isFetchingCreateAccident = true;
        const response: any = yield axios.post(endpoints.createAccident, data);
        log('createAccidentResponse >>>>', response);
        yield self.getAccidentsList();
        return response.data;
      } catch (err) {
        errorLog('createAccidentError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingCreateAccident = false;
      }
    }),
    updateAccident: flow(function* (data) {
      log('updateAccident >>>>', data);
      try {
        self.flags.isFetchingUpdateAccident = true;
        const response = yield axios.post(endpoints.updateAccident, data);
        log('updateAccidentResponse >>>>', response);
        yield self.getAccident({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('updateAccidentError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdateAccident = false;
      }
    }),
    deleteAccident: flow(function* (data) {
      log('deleteAccident >>>>', data);
      try {
        self.flags.isFetchingUpdateAccident = true;
        const response = yield axios.post(endpoints.deleteAccident, data);
        log('deleteAccidentResponse >>>>', response);
        return response.data;
      } catch (err) {
        errorLog('deleteAccidentError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdateAccident = false;
      }
    }),
    updateAccidentVisit: flow(function* (data) {
      log('updateAccidentVisit >>>>', data);
      try {
        self.flags.isFetchingUpdateAccidentVisit = true;
        const response = yield axios.post(endpoints.updateAccidentVisit, data);
        log('updateAccidentVisitResponse >>>>', response);
        yield self.getAccident({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('updateAccidentVisitError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdateAccidentVisit = false;
      }
    }),
    createAccidentLoss: flow(function* (data) {
      log('createAccidentLoss >>>>', data);
      try {
        self.flags.isFetchingCreateAccidentLoss = true;
        const response = yield axios.post(endpoints.createAccidentLoss, data);
        log('createAccidentLossResponse >>>>', response);
        yield self.getAccident({ accidentId: data.accidentId });
        yield self.getAccidentLossList({ accidentId: data.accidentId });
        yield self.getAccidentLossSummary({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('createAccidentError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingCreateAccidentLoss = false;
      }
    }),
    updateAccidentLoss: flow(function* (data) {
      log('updateAccidentLoss >>>>', data);
      try {
        self.flags.isFetchingUpdateAccidentLoss = true;
        const response = yield axios.post(endpoints.updateAccidentLoss, data);
        log('updateAccidentLossResponse >>>>', response);
        yield self.getAccident({ accidentId: data.accidentId });
        yield self.getAccidentLossList({ accidentId: data.accidentId });
        yield self.getAccidentLossSummary({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('updateAccidentLossError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdateAccidentLoss = false;
      }
    }),
    createAccidentAdjustLoss: flow(function* (data) {
      log('createAccidentAdjustLoss >>>>', data);
      try {
        self.flags.isFetchingCreateAccidentAdjustLoss = true;
        const response = yield axios.post(endpoints.createAccidentAdjustLoss, data);
        log('createAccidentAdjustLossResponse >>>>', response);
        yield self.getAccident({ accidentId: data.accidentId });
        yield self.getAccidentLossList({ accidentId: data.accidentId });
        yield self.getAccidentLossSummary({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('createAccidentAdjustLossError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingCreateAccidentAdjustLoss = false;
      }
    }),
    notProceedAccident: flow(function* (data) {
      log('notProceedAccident >>>>', data);
      try {
        self.flags.isFetchingNotProceedAccident = true;
        const response = yield axios.post(endpoints.notProceedAccident, data);
        log('notProceedAccidentResponse >>>>', response);
        yield self.getAccident({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('notProceedAccidentError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingNotProceedAccident = false;
      }
    }),
    deleteAccidentLoss: flow(function* (data) {
      log('deleteAccidentLoss >>>>', data);
      try {
        self.flags.isFetchingDeleteAccidentLoss = true;
        const response = yield axios.patch(endpoints.deleteAccidentLoss(data.accidentExpensesId));
        log('deleteAccidentLossResponse >>>>', response);
        yield self.getAccident({ accidentId: data.accidentId });
        yield self.getAccidentLossList({ accidentId: data.accidentId });
        yield self.getAccidentLossSummary({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('deleteAccidentLossError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingDeleteAccidentLoss = false;
      }
    }),
    deleteAccidentAdjustLoss: flow(function* (data) {
      log('deleteAccidentAdjustLoss >>>>', data);
      try {
        self.flags.isFetchingDeleteAccidentAdjustLoss = true;
        const response = yield axios.patch(
          endpoints.deleteAccidentAdjustLoss(data.accidentAdjustExpensesId),
        );
        log('deleteAccidentAdjustLossResponse >>>>', response);
        yield self.getAccident({ accidentId: data.accidentId });
        yield self.getAccidentLossList({ accidentId: data.accidentId });
        yield self.getAccidentLossSummary({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('deleteAccidentAdjustLossError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingDeleteAccidentAdjustLoss = false;
      }
    }),
    setAccidentAdjustLossDetail: async (data: IAccidentAdjustLoss) => {
      log('setAccidentAdjustLossDetail >>>>', data);
      self.accidentAdjustLoss = cast(data);
    },
    setAccidentLossDetail: async (data: IAccidentLoss) => {
      log('setAccidentLossDetail >>>>', data);
      self.accidentLoss = cast(data);
    },
    updateAccidentAdjustLoss: flow(function* (data) {
      log('updateAccidentAdjustLoss >>>>', data);
      try {
        self.flags.isFetchingUpdateAccidentAdjustLoss = true;
        const response = yield axios.post(endpoints.updateAccidentAdjustLoss, data);
        log('updateAccidentAdjustLossResponse >>>>', response);
        yield self.getAccident({ accidentId: data.accidentId });
        yield self.getAccidentLossList({ accidentId: data.accidentId });
        yield self.getAccidentLossSummary({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('updateAccidentAdjustLossError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdateAccidentAdjustLoss = false;
      }
    }),
    createAccidentEvidenceImage: flow(function* (data) {
      log('createAccidentEvidenceImage >>>>', data);
      try {
        self.flags.isFetchingCreateAccidentEvidenceImage = true;
        const response = yield axios.post(endpoints.createAccidentEvidenceImage, data);
        log('createAccidentEvidenceImageResponse >>>>', response);
        yield self.getAccidentEvidencesImages({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('createAccidentEvidenceImageError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingCreateAccidentEvidenceImage = false;
      }
    }),
    deleteAccidentEvidenceImage: flow(function* (data) {
      log('deleteAccidentEvidenceImage >>>>', data);
      try {
        self.flags.isFetchingDeleteAccidentEvidenceImage = true;
        const response: any = yield axios.delete(
          endpoints.deleteAccidentEvidenceImage(data.accidentEvidenceImageId),
          data,
        );
        log('deleteAccidentEvidenceImageResponse >>>>', response);
        yield self.getAccidentEvidencesImages({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('deleteAccidentEvidenceImageError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingDeleteAccidentEvidenceImage = false;
      }
    }),
    saveAccidentReport: flow(function* (data) {
      log('saveAccidentReport >>>>', data);
      try {
        self.flags.isFetchingSaveAccidentReport = true;
        const response = yield axios.post(endpoints.saveAccidentReport, data);
        log('saveAccidentReportResponse >>>>', response);
        yield self.getAccidentReport({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('saveAccidentReportError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingSaveAccidentReport = false;
      }
    }),
    determinateLoss: flow(function* (data) {
      log('determinateLoss >>>>', data);
      try {
        self.flags.isFetchingUpdateAccidentStatus = true;
        const response = yield axios.post(endpoints.updateAccidentStatus, data);
        log('determinateLossResponse >>>>', response);
        yield self.getAccident({ accidentId: data.accidentId });
        yield self.getAccidentLossList({ accidentId: data.accidentId });
        yield self.getAccidentLossSummary({ accidentId: data.accidentId });
        yield self.getAccidentReport({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('determinateLossError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingUpdateAccidentStatus = false;
      }
    }),
    closeAccident: flow(function* (data) {
      log('closeAccident >>>>', data);
      try {
        self.flags.isFetchingCloseAccident = true;
        const response = yield axios.post(endpoints.closeAccident, data);
        log('closeAccidentResponse >>>>', response);
        yield self.getAccident({ accidentId: data.accidentId });
        yield self.getAccidentLossList({ accidentId: data.accidentId });
        yield self.getAccidentLossSummary({ accidentId: data.accidentId });
        yield self.getAccidentReport({ accidentId: data.accidentId });
        return response.data;
      } catch (err) {
        errorLog('closeAccidentError >>>>', err);
        throw err;
      } finally {
        self.flags.isFetchingCloseAccident = false;
      }
    }),
  }));

export interface IAccidentsStore extends Instance<typeof AccidentsStore> {}

export default AccidentsStore;
