export const HASHES = [
  '#accidents',
  '#accident',
  '#visitData',
  '#accidentLoss',
  '#closeInfo',
  '#kpis',
];

export const ROUTES = {
  LOGIN: '/login',
  HOME: '/home',
};
