import { applySnapshot, types } from 'mobx-state-tree';

const Flags = types
  .model({
    isFetchingAccidentListData: types.optional(types.boolean, false),
    isFetchingAccidentTypesListData: types.optional(types.boolean, false),
    isFetchingCreateAccident: types.optional(types.boolean, false),
    isFetchingAccidentData: types.optional(types.boolean, false),
    isFetchingUpdateAccident: types.optional(types.boolean, false),
    isFetchingDeleteAccident: types.optional(types.boolean, false),
    isFetchingUpdateAccidentVisit: types.optional(types.boolean, false),
    isFetchingCreateAccidentLoss: types.optional(types.boolean, false),
    isFetchingAccidentLossListData: types.optional(types.boolean, false),
    isFetchingAccidentLossSummaryData: types.optional(types.boolean, false),
    isFetchingCreateAccidentAdjustLoss: types.optional(types.boolean, false),
    isFetchingNotProceedAccident: types.optional(types.boolean, false),
    isFetchingDeleteAccidentLoss: types.optional(types.boolean, false),
    isFetchingDeleteAccidentAdjustLoss: types.optional(types.boolean, false),
    isFetchingUpdateAccidentAdjustLoss: types.optional(types.boolean, false),
    isFetchingCreateAccidentEvidenceImage: types.optional(types.boolean, false),
    isFetchingGetAccidentEvidencesImages: types.optional(types.boolean, false),
    isFetchingDeleteAccidentEvidenceImage: types.optional(types.boolean, false),
    isFetchingSaveAccidentReport: types.optional(types.boolean, false),
    isFetchingGetAccidentReport: types.optional(types.boolean, false),
    isFetchingUpdateAccidentStatus: types.optional(types.boolean, false),
    isFetchingCloseAccident: types.optional(types.boolean, false),
    isFetchingUpdateAccidentLoss: types.optional(types.boolean, false),
    isFetchingAccidentsKpis: types.optional(types.boolean, false),
  })
  .actions((self) => ({
    updateFlag: (field: string, value: string | number | boolean | null) => {
      applySnapshot(self, { ...self, [field]: value });
    },
  }));

export default Flags;
